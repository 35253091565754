import React from "react";
import "./TermsOfService.css";

const TermsOfService = () => {
  return (
    <div className="container">
      <h1 className="header">Terms of Service</h1>
      <span className="sub-text">
        <strong>Last Updated:</strong> November 10, 2024
      </span>
      <br />
      <span className="sub-text">
        Welcome to <strong>RushBee</strong>! By accessing or using our services,
        including booking gaming cafes, participating in tournaments, or
        subscribing to any premium services, you agree to the following Terms of
        Service ("TOS"). If you do not agree with these terms, please refrain
        from using our services.
      </span>

      <section className="section">
        <h2 className="sub-header">1. Services Provided</h2>
        <ul className="list">
          <li className="list-item">
            <strong>Gaming Cafe Bookings:</strong> Reserve gaming slots at
            gaming cafes available in your area.
          </li>
          <li className="list-item">
            <strong>Tournaments:</strong> Participate in or host esports
            tournaments.
          </li>
          <li className="list-item">
            <strong>Gold Membership Pricing Plans:</strong> Access additional
            benefits through RushBee's Gold Membership plan.
          </li>
        </ul>
      </section>

      <section className="section">
        <h2 className="sub-header">2. User Responsibilities</h2>
        <ul className="list">
          <li className="list-item">
            <strong>Permitted Actions:</strong> Users are allowed to book gaming
            slots, participate in tournaments, and engage with other RushBee
            features.
          </li>
          <li className="list-item">
            <strong>Prohibited Actions:</strong> Users must refrain from any
            behavior that could disrupt or damage the experience for others,
            such as:
            <ul className="nested-list">
              <li className="nested-list-item">Cheating in tournaments</li>
              <li className="nested-list-item">
                Harassing other users or cafe staff
              </li>
              <li className="nested-list-item">
                Sharing inappropriate content
              </li>
            </ul>
          </li>
          <li className="list-item">
            <strong>Reporting Misconduct:</strong> If a user violates these
            terms, they may be reported by either RushBee or a cafe owner.
            Violations may lead to account suspension or termination.
          </li>
        </ul>
      </section>

      <section className="section">
        <h2 className="sub-header">3. Account Creation and Management</h2>
        <span className="sub-text">
          To use RushBee’s services, you must create an account by providing:
        </span>
        <ul className="list">
          <li className="list-item">Phone number</li>
          <li className="list-item">Email address</li>
          <li className="list-item">Full name</li>
          <li className="list-item">Profile picture (optional)</li>
        </ul>
        <span className="sub-text">
          There are no age restrictions for account creation, but users must
          ensure that the information provided is accurate and up-to-date.
        </span>
      </section>

      <section className="section">
        <h2 className="sub-header">4. Bookings and Payments</h2>
        <span className="sub-text">
          <strong>Booking Slots:</strong> Gaming slots and tournament
          participation are available based on date and time. You can view and
          book available slots through the app.
        </span>
        <br />
        <span className="sub-text">
          <strong>Payment:</strong> Payments for bookings and services will be
          processed through a secure third-party payment gateway, which will
          collect both the booking amount and RushBee's commission.
        </span>

        <h3 className="sub-sub-header">Cancellation Policy:</h3>
        <ul className="list">
          <li className="list-item">
            <strong>0-3 Hours Before Booking Start Time:</strong> A 10%
            deduction will apply to the booking amount if canceled within 0-3
            hours.
          </li>
          <li className="list-item">
            <strong>More Than 3 Hours Before Booking Start Time:</strong> No
            deduction will be applied if canceled more than 3 hours before the
            scheduled booking.
          </li>
        </ul>
      </section>

      <section className="section">
        <h2 className="sub-header">5. Data Privacy</h2>
        <span className="sub-text">
          RushBee collects and stores personal data such as:
        </span>
        <ul className="list">
          <li className="list-item">Name</li>
          <li className="list-item">Phone number</li>
          <li className="list-item">Email address</li>
          <li className="list-item">Gender and age</li>
          <li className="list-item">Profile picture</li>
        </ul>
        <span className="sub-text">
          This data will be stored safely and securely. RushBee is committed to
          safeguarding your privacy. We do not share your data with third
          parties unless required for the proper functioning of our services
          (e.g., payment processing).
        </span>
      </section>

      <section className="section">
        <h2 className="sub-header">6. Intellectual Property</h2>
        <span className="sub-text">
          <strong>RushBee Content:</strong> All content provided by RushBee,
          including but not limited to graphics, logos, and written materials,
          is owned by RushBee or its licensors.
        </span>
        <br />
        <span className="sub-text">
          <strong>User-Generated Content:</strong> Users retain ownership of any
          content they upload, such as profile pictures, but grant RushBee a
          license to use this content for service-related purposes.
        </span>
      </section>

      <section className="section">
        <h2 className="sub-header">7. Limitation of Liability</h2>
        <span className="sub-text">
          RushBee is not liable for system downtime, booking errors, or
          technical issues that may affect your ability to use the platform.
        </span>
        <span className="sub-text">
          RushBee is not responsible for any third-party actions, such as gaming
          cafes not honoring bookings or tournament organizers changing
          schedules.
        </span>
      </section>

      <section className="section">
        <h2 className="sub-header">8. Dispute Resolution</h2>
        <span className="sub-text">
          Any disputes that arise will be governed by Indian law. If a dispute
          cannot be resolved informally, users agree to submit to arbitration or
          mediation, as applicable.
        </span>
      </section>

      <section className="section">
        <h2 className="sub-header">9. Changes to Terms</h2>
        <span className="sub-text">
          RushBee reserves the right to modify these Terms of Service at any
          time. Users will be notified of any changes via in-app notification or
          a pop-up UI. It is your responsibility to review these terms
          periodically to ensure you remain in compliance.
        </span>
      </section>

      <section className="section">
        <h2 className="sub-header">10. Governing Law</h2>
        <span className="sub-text">
          These Terms of Service are governed by and construed in accordance
          with the laws of India. Any disputes arising out of or in connection
          with these terms shall be subject to the exclusive jurisdiction of the
          courts located in India.
        </span>
      </section>
    </div>
  );
};

export default TermsOfService;
