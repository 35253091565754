import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import "./App.css";
import AdminAuth from "./components/Authentication/adminAuth";
import ComingSoon from "./components/LandingPage/ComingSoon";
import TermsOfService from "./components/FooterPages/tos"
import Privacy from "./components/FooterPages/Privacy";
import Main from "./pages/Main";
import CafeLayout from "./pages/Layout";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/admin" element={<AdminAuth />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/tos" element={<TermsOfService />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/dash" element={<Main />} />
        <Route path="/layout" element={<CafeLayout />} />
      </Routes>
    </Router>
  );
};

export default App;
