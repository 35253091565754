import React, { useEffect, useRef, useState } from "react";
import whiteLogo from "../../assets/footer-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./LandingPage.css";

function LandingPage() {
  const [sidebarActive, setSidebarActive] = useState(false);

  const toggleSidebar = () => {
    setSidebarActive(!sidebarActive);
  };

  return (
    <div className="mainContainer">
      {/* Navbar */}
      <nav className="navStyle">
        <a href="/home" className="logo-container">
          <img
            src="/images/logo-pink.png"
            alt="RushBee Logo"
            className="logoImage"
          />
          <span className="logoText">RushBee</span>
        </a>

        {/* Menu Links */}
        <div className="nav-links">
          <a href="#home" className="navText">
            Home
          </a>
          <a href="#partnerApp" className="navText">
            Partner App
          </a>

          <a href="#contact" className="navText">
            Contact
          </a>
        </div>
        <div className="hamburger" onClick={toggleSidebar}>
          &#9776;
        </div>
      </nav>
      {/* Overlay */}
      {sidebarActive && <div className="overlay" onClick={toggleSidebar}></div>}
      {/* Sidebar */}
      <div className={`sidebar ${sidebarActive ? "active" : ""}`}>
        <a href="#" className="close-btn" onClick={toggleSidebar}>
          &times;
        </a>
        <a href="#home" onClick={toggleSidebar}>
          Home
        </a>
        <a href="#partnerApp" onClick={toggleSidebar}>
          Partner App
        </a>
        <a href="#contact" onClick={toggleSidebar}>
          Contact
        </a>
      </div>
      {/* First Section */}
      <section id="home" className="firstSection">
        <div className="custom-container">
          <div className="custom-text">
            <h1 className="heading-text">
              Ultimate <span className="text-[#FF0073]">Gateway</span>
              <br />
              to Gaming &<br />
              Esports
            </h1>
            <p className="content-p-tag">
              RushBee is your all-in-one platform to book slots, manage events,
              and dominate the esports scene. Discover tournaments, team up with
              gamers, find resources, and unlock rewards.
            </p>
            <div className="button-container">
              <a href="coming-soon" className="button-play">
                <img
                  src="/images/google-play-badge.png"
                  alt="Get it on Google Play"
                  className="play"
                />
              </a>
              <a href="coming-soon" className="button-play-apple">
                <img
                  src="/images/app-store-badge.png"
                  alt="Download on App Store"
                  className="play"
                />
              </a>
            </div>
          </div>
          <div>
            <img
              src="/images/image-userApp.png"
              alt="RushBee App Interface"
              className="app-image"
            />
          </div>
        </div>
      </section>

      {/* Second Section */}
      <section id="partnerApp" className="secondSection">
        <div className="custom-container">
          <div className="custom-text">
            <h1>
              Got a Cafe?
              <br />
              Host with <span className="text-[#FF0073]">RushBee</span>
            </h1>
            <p className="content-p-tag">
              Turn your gaming cafe into an esports hub with the RushBee Partner
              App! Manage bookings, host events, attract gamers, and put your
              cafe on the esports map.
            </p>
            {/* Button container */}
            <div className="button-container">
              <a href="coming-soon" className="button-play">
                <img
                  src="/images/google-play-badge.png"
                  alt="Get it on Google Play"
                  className="play"
                />
              </a>
              <a href="coming-soon" className="button-play-apple">
                <img
                  src="/images/app-store-badge.png"
                  alt="Download on App Store"
                  className="play"
                />
              </a>
            </div>
          </div>
          <div>
            <img
              src="/images/image-partnerApp.png"
              alt="partnerapp-ui-alt"
              className="app-image2"
              draggable="false"
            />
          </div>
        </div>
      </section>
      {/* Footer  */}
      <footer id="contact" className="footer">
        <div className="footer-container">
          {/* Left side - Logo and Copyright */}
          <div className="footer-left">
            <div className="logoContainer">
              <img src={whiteLogo} alt="RushBee Logo" className="footer-logo" />
            </div>
            <span className="footer-content">
              A place where gamers book, game & compete 🎯
            </span>
            <span className="footer-copyright">
              © 2024 NodeCraft. All rights reserved.
            </span>
          </div>

          {/* Right side - Contact and Social Links */}
          <div className="footer-right">
            <div className="footer-links">
              <a href="/privacy" className="footer-link">
                Privacy Policy
              </a>
              <a href="/tos" className="footer-link">
                Terms of Service
              </a>
              <a href="/coming-soon" className="footer-link">
                Pricing
              </a>
            </div>
            <div className="social-icons">
              <a
                href="https://x.com/rushbeegaming"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faXTwitter} size="2x" />
              </a>
              <a
                href="https://instagram.com/rushbee.gaming"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="mailto:contact@rushbeegaming.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
