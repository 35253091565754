import { useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import '../styles/layout.css'
function CafeLayout() {
  const location = useLocation();
  const cafeId = location.state?.cafeId || null;
  const cafeName = location.state?.cafeName || null;
  const [seats, setSeats] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editFormData, setEditFormData] = useState({
    seatNumber: '',
    enabled: true,
    pricing: ''
  });
  const [availablePricings, setAvailablePricings] = useState([]);
  const canvasRef = useRef(null);

  // Fetch seats with populated pricing data
  const fetchSeatsForCafe = async (cafeId) => {
    try {
      const response = await fetch(`http://localhost:3000/api/seats/cafe/${cafeId}`);
      const data = await response.json();
      console.log(data);
      setSeats(data.seats);
    } catch (error) {
      console.error("Error fetching seats:", error);
    }
  };

  // Fetch available pricing options
  const fetchPricings = async () => {
    try {
      const response = await fetch(`http://localhost:3000/api/pricing/cafe/${cafeId}`);
      const data = await response.json();
      console.log(data);
      
      setAvailablePricings(data);
    } catch (error) {
      console.error("Error fetching pricings:", error);
    }
  };

  useEffect(() => {
    if (cafeId) {
      fetchSeatsForCafe(cafeId);
      fetchPricings();
    }
  }, [cafeId]);

  useEffect(() => {
    if (selectedSeat && !isEditMode) {
      setEditFormData({
        seatNumber: selectedSeat.seatNumber,
        enabled: selectedSeat.enabled,
        pricing: selectedSeat.pricing?._id || selectedSeat.pricing
      });
    }
  }, [selectedSeat]);

  function snapToNearest20(value) {
    return Math.round(value / 20) * 20;
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    seats.forEach((seat) => {
      drawSeat(ctx, seat);
    });
  }, [seats]);

  const handleCanvasClick = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const clickedSeat = seats.find(
      (seat) => Math.hypot(seat.xPosition - x, seat.yPosition - y) < 20
    );

    if (clickedSeat) {
      setSelectedSeat(clickedSeat);
      setIsModalOpen(true);
      setIsEditMode(false);
    }
  };

  const drawSeat = (ctx, seat) => {
    const color = seat.enabled ? 'green' : 'red';
    ctx.beginPath();
    ctx.arc(seat.xPosition, seat.yPosition, 20, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.fill();
    ctx.closePath();
    
    // Add seat number text
    ctx.fillStyle = 'white';
    ctx.font = '12px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(seat.seatNumber, seat.xPosition, seat.yPosition);
  };

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const draggedSeat = seats.find(
      (seat) => Math.hypot(seat.xPosition - x, seat.yPosition - y) < 20
    );

    if (draggedSeat) {
      const handleMouseMove = (moveEvent) => {
        const newX = moveEvent.clientX - rect.left;
        const newY = moveEvent.clientY - rect.top;
        setSeats((prevSeats) =>
          prevSeats.map((seat) =>
            seat._id === draggedSeat._id
              ? { ...seat, xPosition: snapToNearest20(newX), yPosition: snapToNearest20(newY) }
              : seat
          )
        );
        draggedSeat.xPosition = snapToNearest20(newX);
        draggedSeat.yPosition = snapToNearest20(newY);
      };

      const handleMouseUp = async () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);

        try {
          await fetch(`http://localhost:3000/api/seats/${draggedSeat._id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              xPosition: draggedSeat.xPosition,
              yPosition: draggedSeat.yPosition,
            }),
          });
        } catch (error) {
          console.error("Error updating seat position:", error);
        }
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`http://localhost:3000/api/seats/${selectedSeat._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editFormData),
      });

      if (response.ok) {
        const updatedSeat = await response.json();
        setSeats(seats.map(seat => 
          seat._id === selectedSeat._id ? updatedSeat.data : seat
        ));
        setIsEditMode(false);
        setIsModalOpen(false);
        fetchSeatsForCafe(cafeId); // Refresh seats to get updated data
      }
    } catch (error) {
      console.error("Error updating seat:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSeat(null);
    setIsEditMode(false);
  };

  const formatPricingInfo = (pricing) => {
    if (!pricing) return "No pricing assigned";
    return (
      <div
        style={{
          backgroundColor: "#1b1b1b",
          margin: " 30px 380px",
          padding: "40px",
          borderRadius: "20px",
        }}
      >
        <p style={{ color: "white", fontSize: "20px" }}>
          <strong>Device:</strong> {pricing.device}
        </p>
        <p style={{ color: "white", fontSize: "20px" }}>
          <strong>Specs:</strong> {pricing.deviceSpecs}
        </p>
        <p style={{ color: "white", textAlign: "center", fontSize: "20px" }}>
          <strong>Rates:</strong>

          <ul style={{ color: "white", alignItems: "center" }}>
            {pricing.rateList?.map((rate, index) => (
              <li
                key={index}
                style={{ color: "white", justifyContent: "center" }}
              >
                {rate.hours} hours: ₹{rate.price}
              </li>
            ))}
          </ul>
        </p>
      </div>
    );
  };

  return (
    <div
      style={{
        padding: "40px",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <h2 style={{ fontSize: "30px", fontWeight: "bold" }}>
        Cafe Layout - {cafeName}
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "60px",
        }}
      >
        <canvas
          ref={canvasRef}
          width={1000}
          height={700}
          style={{
            backgroundColor: "#1b1b1b",
            borderRadius: "20px",
            justifyItems: "center",
          }}
          onMouseDown={handleMouseDown}
          onClick={handleCanvasClick}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Seat Details"
        ariaHideApp={false}
        style={{ content: { backgroundColor: "#000" } }}
      >
        <h2
          style={{
            fontSize: "50px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          {isEditMode ? "Edit Seat" : "Seat Details"}
        </h2>

        {isEditMode ? (
          <form onSubmit={handleEditSubmit}>
            <div className="container-flex">
              <label className="label-text">Seat Number: {"  "} </label>
              <input
                type="text"
                name="seatNumber"
                value={editFormData.seatNumber}
                onChange={handleInputChange}
                style={{
                  backgroundColor: "#1b1b1b",
                  padding: "10px",
                  borderRadius: "20px",
                }}
              />
            </div>
            <div className="container-flex">
              <label className="label-text">Enabled: </label>
              <input
                type="checkbox"
                name="enabled"
                checked={editFormData.enabled}
                onChange={handleInputChange}
                style={{ backgroundColor: "#1b1b1b" }}
              />
            </div>
            <div className="container-flex">
              <label>Pricing: </label>
              <select
                name="pricing"
                value={editFormData.pricing}
                onChange={handleInputChange}
                style={{
                  backgroundColor: "#1b1b1b",
                  padding: "10px",
                  borderRadius: "20px",
                }}
              >
                <option value="">Select Pricing</option>
                {availablePricings.map((pricing) => (
                  <option key={pricing._id} value={pricing._id}>
                    {pricing.device} - {pricing.deviceSpecs}
                  </option>
                ))}
              </select>
            </div>
            <div className="container-flex">
              <button type="submit" className="button-admin">
                Update
              </button>
              <button
                type="button"
                className="button-admin-outline"
                onClick={() => setIsEditMode(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>Seat Number:</strong> {"   "} {selectedSeat?.seatNumber}
            </p>
            <p>
              <strong>Position:</strong> ({selectedSeat?.xPosition},{" "}
              {selectedSeat?.yPosition})
            </p>
            <p>
              <strong>Enabled:</strong> {selectedSeat?.enabled ? "Yes" : "No"}
            </p>
            <div>
              <strong style={{ fontSize: "30px" }}>Pricing Details</strong>
              {formatPricingInfo(selectedSeat?.pricing)}
            </div>
            <div className="container-flex">
              <button
                className="button-admin"
                onClick={() => setIsEditMode(true)}
              >
                Edit
              </button>
              <button className="button-admin-outline" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default CafeLayout;