import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();
  const [cafes, setCafes] = useState([]);

  // Fetch all cafes from the backend
  useEffect(() => {
    const fetchCafes = async () => {
      try {
        const response = await fetch('http://localhost:3000/api/cafe'); // Adjust the endpoint as needed
        console.log(response);
        
        const data = await response.json();
        console.log(data);
        setCafes(data.gamingCafes);
      } catch (error) {
        console.error('Error fetching cafes:', error);
      }
    };

    fetchCafes();
  }, []);

  const handleNavigate = (cafeId, cafeName) => {
    navigate("/layout", { state: { cafeId, cafeName } });
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Cafes Dashboard</h1>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {cafes.map((cafe) => (
          <div key={cafe._id} style={cardStyle}>
            <h2 style={{ ...headerStyle, color: "#fff" }}>{cafe.cafeName}</h2>
            <p style={{ color: "#fff", marginBottom: "20px" }}>
              <strong>Address:</strong> {cafe.cafeAddress}
            </p>
            <p style={{ color: "#fff", marginBottom: "20px" }}>
              <strong>Description:</strong> {cafe.cafeDescription}
            </p>
            <p style={{ color: "#fff", marginBottom: "20px" }}>
              <strong>Phone:</strong> {cafe.cafePhone}
            </p>
            <p style={{ color: "#fff", marginBottom: "20px" }}>
              <strong>Email:</strong> {cafe.cafeEmail}
            </p>
            <p style={{ color: "#fff", marginBottom: "20px" }}>
              <strong>Total Slots (PC):</strong> {cafe.totalSlotsPc}
            </p>
            <p style={{ color: "#fff", marginBottom: "20px" }}>
              <strong>Total Slots (Console):</strong> {cafe.totalSlotsConsole}
            </p>
            <button
              onClick={() => handleNavigate(cafe._id, cafe.cafeName)}
              style={buttonStyle}
            >
              Go to Layout
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

// Styles
const cardStyle = {
  borderRadius: "30px",
  padding: "40px",
  width: "550px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s",
  backgroundColor: "#000",
  overflow: "hidden",
  height: "100%",
};

const headerStyle = {
  marginBottom: '10px',
  color: '#2c3e50',
  fontSize: '35px',
  fontWeight: 'bold'
};

const buttonStyle = {
  padding: "10px 25px",
  border: "none",
  borderRadius: "15px",
  backgroundColor: "#1b1b1b",
  color: "white",
  cursor: "pointer",
  transition: "background-color 0.3s",
  marginTop: "auto",
};

export default HomePage;
