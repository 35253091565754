import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function AdminAuth() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:3000/api/admin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error("Invalid credentials");
      }

      if (data.token) {
        localStorage.setItem("adminToken", data.token);
        // Redirect or handle successful login
        navigate("/dash");
      }
    } catch (err) {
      setError("Invalid credentials");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0E0E15]">
      <div className="bg-[#1A1A24] p-8 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex items-center justify-center mb-8">
          <img
            src="/images/logo-pink.png"
            alt="RushBee Logo"
            className="h-12 mr-2"
          />
          <span className="font-adieu text-2xl text-[#FF0073]">
            RushBee Admin
          </span>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
              {error}
            </div>
          )}

          <div>
            <label className="block text-white text-sm font-medium mb-2">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-700 rounded-md bg-[#2A2A35] text-white focus:outline-none focus:ring-2 focus:ring-[#FF0073] focus:border-transparent"
              required
            />
          </div>

          <div>
            <label className="block text-white text-sm font-medium mb-2">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-700 rounded-md bg-[#2A2A35] text-white focus:outline-none focus:ring-2 focus:ring-[#FF0073] focus:border-transparent"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-[#FF0073] text-white py-2 px-4 rounded-md hover:bg-[#FF1A85] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#FF0073] focus:ring-opacity-50"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminAuth;
