import React from 'react';

const ComingSoon = () => {
  const containerStyle = {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: '#0e0e15',
    fontFamily: '"Rubik", sans-serif',
    color: 'white',
    overflow: 'hidden',
    position: 'relative'
  };

  const svgContainerStyle = {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    zIndex: -1
  };

  const backgroundImageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.5
  };

  const sectionStyle = {
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(14, 14, 21, 0)',
    paddingTop: '5rem',
    zIndex: 20
  };

  const customContainerStyle = {
    width: '100%',
    maxWidth: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1b1b1b',
    borderRadius: '2rem',
    padding: '4rem',
    boxShadow: '10px 10px 42px 10px rgba(0, 0, 0, 0.68)'
  };

  const headingStyle = {
    fontSize: '5rem',
    fontWeight: 'bold',
    marginBottom: '1rem'
  };

  const spanStyle = {
    color: '#ff0073'
  };

  const paragraphStyle = {
    fontSize: '1.5rem',
    marginTop: '2rem',
    color: 'rgba(255, 255, 255, 0.5)',
    lineHeight: '1.8'
  };

  return (
    <div style={containerStyle}>
      <div style={svgContainerStyle}>
        <img 
          src="/background.svg" 
          alt="background pattern" 
          style={backgroundImageStyle}
        />
      </div>
      
      <div style={sectionStyle}>
        <div style={customContainerStyle}>
          <div style={{ textAlign: 'center' }}>
            <h1 style={headingStyle}>
              Coming <span style={spanStyle}>Soon!</span>
            </h1>
            <p style={paragraphStyle}>
              We're working hard to bring you this amazing platform. Stay tuned!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;

